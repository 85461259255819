<div id="top-of-page"></div>

<app-navbar></app-navbar>
<div id="main-content">
  <router-outlet></router-outlet>
</div>
<!-- <div class="spinner-container">
  <mat-spinner *ngIf="loading" id="spinner" color="accent"></mat-spinner>
</div> -->
<app-footer></app-footer>
<!-- <app-contact-button></app-contact-button> -->
