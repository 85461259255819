import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material-experimental/mdc-dialog';
// import { fader } from './route-animations';
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { CookieComponent } from './easter-eggs/cookie/cookie.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  // animations: [fader],
})
export class AppComponent implements OnInit {
  title = 'HER Kitchen';
  loadStyle(styleName: string) {
    const head = this.document.head;

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `${styleName}`;

      head.appendChild(style);
    }
  }
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private viewportScroller: ViewportScroller,
    private dialog: MatDialog
  ) {
    if (this.document.body.classList.contains('dark-mode')) {
      this.loadStyle('dark-mode');
    }

    this.viewportScroller.setOffset([0, 100]);
    this.viewportScroller.setHistoryScrollRestoration('manual');
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
        Promise.all(
          Array.from(this.document.images)
            .filter((img: any) => !img.complete)
            .map(
              (img: any) =>
                new Promise((resolve) => {
                  img.onload = img.onerror = resolve;
                })
            )
        ).then(() => {
          this.loading = false;
        });
      }
      if (event instanceof NavigationEnd) {
        this.loading = true;
        Promise.all(
          Array.from(this.document.images)
            .filter((img: any) => !img.complete)
            .map(
              (img: any) =>
                new Promise((resolve) => {
                  img.onload = img.onerror = resolve;
                })
            )
        ).then(() => {
          this.loading = false;
        });
      }
    });
  }
  prepareRoute(outlet: RouterOutlet) {
    return (
      outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
    );
  }
  loading: boolean = true;
  ngOnInit() {
    addEventListener('keydown', (e) => {
      if (e.key.toLowerCase() === 'c') {
        addEventListener('keydown', (e) => {
          if (e.key.toLowerCase() === 'o') {
            addEventListener('keydown', (e) => {
              if (e.key.toLowerCase() === 'o') {
                addEventListener('keydown', (e) => {
                  if (e.key.toLowerCase() === 'k') {
                    addEventListener('keydown', (e) => {
                      if (e.key.toLowerCase() === 'i') {
                        addEventListener('keydown', (e) => {
                          if (e.key.toLowerCase() === 'e') {
                            console.log('cooookie');
                            this.dialog.open(CookieComponent);
                            // @ts-ignore
                            window.removeAllListeners('keydown');
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
}
