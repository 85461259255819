import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  ViewContainerRef,
} from '@angular/core';

import { MatDialog } from '@angular/material-experimental/mdc-dialog';

import { DialogComponent } from './dialog/dialog.component';
import { pages } from './pages';

import { ViewportScroller } from '@angular/common';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit {
  constructor(
    private router: Router,

    @Inject(DOCUMENT) private document: Document,
    public dialog: MatDialog,

    private viewportScroller: ViewportScroller
  ) {}
  hideLoader(): void {}

  signedIn: boolean = false;
  openDialog() {
    this.dialog.open(DialogComponent);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      document.getElementById('loader')!.setAttribute('style', 'display: none');
    }, 0);
  }
  share() {
    if (navigator.share) {
      navigator
        .share({
          title: 'Check out this bakery!',
          text: 'Check out this vegan and gluten free bakery!',
          url: 'https://herkitchenseattle.com',
        })
        .then(() => {
          console.log('Successfully shared!');
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }
  pages = pages;
  debounce(fn: Function) {
    let frame: any;

    return (...params: any[]) => {
      if (frame) {
        cancelAnimationFrame(frame);
      }
      frame = requestAnimationFrame(() => {
        fn(...params);
      });
    };
  }
  scrollToTop() {
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    this.viewportScroller.scrollToPosition([0, 0]);
  }
  storeScroll() {
    if (typeof document !== undefined) {
      document.body.dataset.scroll = Math.floor(window.scrollY).toString();
      document.querySelector<HTMLDivElement>('#navbar')!.dataset.scroll =
        Math.floor(window.scrollY).toString();
    }
  }
  /**
   * @param el The element to scroll into view
   */
  async scroll(el: string) {
    if (this.router.url !== '/menu') {
      this.router.navigateByUrl('/menu').then(() => {
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor(el);
        }, 500);
      });
    } else {
      this.viewportScroller.scrollToAnchor(el);
    }
  }
  console = console;
  goToMenu(): void {
    this.router.navigateByUrl('/menus');
  }
  async ngOnInit() {
    this.document.addEventListener('scroll', this.debounce(this.storeScroll), {
      passive: true,
    });
    this.storeScroll();
  }
}
