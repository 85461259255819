<main class="main">
  <h1 class="text-center">HER Kitchen</h1>
  <h2 class="text-center">
    Vegan and gluten free treats. <br />
    Making a sweet connection one bite at a time.
  </h2>
  <div
    id="carouselExampleIndicators"
    class="carousel slide"
    data-bs-ride="carousel"
  >
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="2"
        aria-label="Slide 3"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="3"
        aria-label="Slide 4"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="4"
        aria-label="Slide 5"
      ></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          src="../../assets/images/C5.png"
          class="d-block w-100"
          width="100%"
          height="100%"
          alt="Cookies"
          loading="eager"
        />
      </div>
      <div class="carousel-item">
        <img
          src="../../assets/images/C4.jpg"
          class="d-block w-100"
          width="100%"
          height="100%"
          alt="Cookies"
          loading="eager"
        />
      </div>
      <div class="carousel-item">
        <img
          src="../../assets/images/C3.jpg"
          class="d-block w-100"
          width="100%"
          height="100%"
          alt="Assortment of bars"
          loading="eager"
        />
      </div>
      <div class="carousel-item">
        <img
          src="../../assets/images/C2.jpg"
          class="d-block w-100"
          width="100%"
          height="100%"
          alt="Assortment of cookies"
          loading="eager"
        />
      </div>
      <div class="carousel-item">
        <img
          src="../../assets/images/C1.jpg"
          class="d-block w-100"
          width="100%"
          height="100%"
          alt="Assortment of cookies"
          loading="eager"
        />
      </div>
    </div>

    <button
      class="carousel-control-prev bs-prev"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="prev"
    >
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    </button>
    <button
      class="carousel-control-next bs-next"
      type="button"
      data-bs-target="#carouselExampleIndicators"
      data-bs-slide="next"
    >
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
    </button>
  </div>
</main>
