<main>
  <h1>Branding</h1>
  <div
    style="
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    "
  >
    <a
      href="/assets/branding-kit.zip"
      target="_blank"
      style="align-self: center"
      color="accent"
      mat-flat-button
      matTooltip="Download Branding Kit"
    >
      Download Branding Kit
    </a>
  </div>

  <div style="height: 10px"></div>
  <mat-divider></mat-divider>

  <h2>Guidelines</h2>
  <mat-list>
    <mat-list-item>
      Don't modify the design or color of our assets <br />
      &nbsp;&nbsp;&nbsp;&nbsp; Stylizing, warping, or modifying their color or
      shape is not allowed <br />
      &nbsp;&nbsp;&nbsp;&nbsp; If you are unable to use the correct color due to
      technical limitations, you may revert to black and white
    </mat-list-item>
  </mat-list>
</main>
