<footer>
  <div class="footer-grid">
    <div></div>
    <div>
      <span>
        <h1>
          <a routerLink="/"
            ><img
              alt="HER Kitchen Logo"
              src="/assets/images/icon.png"
              height="80"
          /></a>
          <!-- HER Kitchen -->
        </h1>
        <h3>A vegan and gluten free bakery based in Seattle, Washington.</h3>

        <div class="break"></div>
        <h3>© {{ year }} HER Kitchen Seattle <br /></h3>

        <div class="break"></div>
        <h3>
          <a
            (click)="scrollToTop()"
            class="topLink"
            style="
              user-select: none;
              -ms-user-select: none;
              -webkit-user-select: none;
              -moz-user-select: none;
            "
            >Scroll to Top</a
          >
        </h3>
      </span>
    </div>
    <div>
      <h2>Links:</h2>

      <h4 class="links">
        <!-- <a (click)="scrollToTop()" routerLink="/branding">Branding</a> -->
        <a (click)="scrollToTop()" routerLink="/">Home</a>
        <a (click)="scrollToTop()" routerLink="/about">About</a>
        <a (click)="scrollToTop()" routerLink="/menu">Menu</a>
        <a (click)="scrollToTop()" routerLink="/events">Events</a>
        <a (click)="scrollToTop()" routerLink="/contact">Contact</a>
        <!-- <a (click)="scrollToTop()" routerLink="/changelog">Changelog</a> -->
      </h4>
    </div>
    <div>
      <h2>Social Media:</h2>
      <h4 class="links">
        <a routerLink="/facebook" target="_blank" class="fb"> Facebook </a>

        <a routerLink="/instagram" target="_blank"> Instagram </a>

        <!-- 
          <a
            href="https://github.com/HERKitchenSeattle/website"
            target="_blank"
            rel="noreferrer"
            >View project on GitHub
          </a>-->

        <a routerLink="/twitter" target="_blank"> Twitter </a>
      </h4>
    </div>
    <div></div>
  </div>
  <div style="display: flex; margin-left: 25px">
    <span
      style="
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: flex-start;
        flex-wrap: wrap;
      "
    >
    </span>
  </div>
  <!-- <mat-divider style="margin-bottom: 10px"></mat-divider>
  <div style="display: block">
    <div class="break"></div>
    <h6>
      <a (click)="scrollToTop()" routerLink="/admin/login">Admin login</a>
    </h6>
    <div class="break"></div>
  </div> -->
</footer>
