<main>
  <span>
    <h1>HER Values</h1>
  </span>

  <p>
    <br />
    Making a sweet connection, one treat at a time. Every ingredient is simple,
    thoughtful and necessary. No unhealthy fillers, food coloring or
    preservatives. HER Kitchen is changing the outdated stigma that gluten free
    cookies are boring and blend.
  </p>
  <span>
    <h2>HER Vision</h2>
  </span>
  <p>
    Bake with my heart! I want people to start thinking what they put in their
    mouth. Not just a matter of being healthy, but also the whys. I will never
    say muffins and brownies are healthy, but when I baked it with my hearts and
    I gave it to my friends, it has my love in it and I think, they feel it
    (with calories). Before I turned making chocolate and baking into business,
    I would read the labels in detail before I buy a commercial goodies to my
    friends. Now I sell what I preach, I screen the ingredients and they are not
    necessarily the cheapest but they are GOOD ingredients I stand by.
  </p>
  <span>
    <h2>HER Story</h2>
  </span>
  <p>
    My baking journey started when I went into the 3rd trimester with our son. I
    was nesting big-time; I just wanted to bake.<br />
    Initially, I just wanted to bake for my family and friends; now I want to
    bake for people who would like to share the same values as me.
    <i>Every bite has my best intention in it.</i>™ I want to share and connect
    these values with the world through my treats.<br />
    Fast forward 4 years: we decided as a family to adopt a vegan diet. My
    husband also discovered dairy was not good for him. WOW, that made eating
    and baking a very interesting challenge: flat pancakes, unfluffy
    scones...etc - and I thought egg replacer was my only vegan baking
    friend!<br />
    Then fast forward 6 more years: we learned my husband was gluten intolerant.
    So, baking needed to be completely gluten-free for him. Rather than baking
    something gluten for me and my son, and another batch gluten-free, I slowly
    but surely switched to 100% gluten-free baking at home.<br />
    Since then, being a stay-at-home mom has given me more opportunities to
    experiment.<br />
    My friends loved my chocolate and baking often ask, why not turn it into a
    business?<br />
    HER came from my original branding <b>H</b>ealthi<b>ER</b>
    chocolates/treats, but part of my journey was learning about how to turn my
    dream into a business. During the permitting process, I hit some roadblocks
    with the word “healthier,” so I had to skip that claim in order to get the
    process moving.<br />
    On August 2, 2018, I launched HER Chocolate: vegan and gluten-free dark
    chocolates. <br />
    That December, HER Kitchen was launched to serve vegan and gluten-free baked
    treats. Since then, we have expanded not only cookies, but oat bars, keto
    bars, brownies, and lemon bars. We would like to build healthy communities,
    one sweet bite at a time!
  </p>
  <h2 class="signature">- Laura</h2>
  <img
    src="../../assets/images/s-icon.png"
    alt="HER Kitchen Logo"
    height="100"
    style="height: 50px; width: 99.8px; object-fit: cover; margin-bottom: 8px"
  />
  <span><h3>Disclamer:</h3></span>
  <p>
    I can bake but I can’t write, so I paid my wonderful friend (with treats) to
    audit my writing when she can.
  </p>
</main>
