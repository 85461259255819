<main class="main">
  <!-- <button mat-flat-button color="accent" (click)="openMessageDialog()">
    Send us a message
  </button> -->
  <h1>Socials:</h1>
  <mat-divider></mat-divider>

  <h2>
    Facebook
    <a target="_blank" routerLink="/facebook">@HERKitchenSeattle</a>
    <br />
    Instagram
    <a routerLink="/instagram" target="_blank" rel="noopener noreferrer"
      >@HERKitchenSeattle</a
    >
  </h2>
  <h1>Contact us by Facebook Messenger for custom orders and catering:</h1>
  <mat-divider></mat-divider>

  <h2>
    <a target="_blank" href="https://m.me/HERKitchenSeattle"
      >Link to Facebook Messenger</a
    >
    <br />
    Or by email:
    <br />
    <a target="_blank" href="mailto:herkitchenseattle@outlook.com"
      >herkitchenseattle@outlook.com</a
    >
  </h2>
</main>
