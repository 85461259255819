<main style="text-align: center; margin-top: 25px">
  <span>
    <h1>This page does not exist.</h1>
  </span>
  <span>Here are some links:</span>
  <span>
    <h4 class="links">
      <a (click)="scrollToTop()" routerLink="/">Home</a>&nbsp;
      <a (click)="scrollToTop()" routerLink="/about">About</a>&nbsp;
      <a (click)="scrollToTop()" routerLink="/menu">Menu</a>&nbsp;
      <a (click)="scrollToTop()" routerLink="/events">Events</a>&nbsp;
      <a (click)="scrollToTop()" routerLink="/contact">Contact</a>&nbsp;
    </h4>
  </span>
</main>
