<h1 mat-dialog-title>Contact us!</h1>
<div mat-dialog-content>
  <form class="example-form" id="messageForm">
    <mat-form-field class="example-full-width">
      <mat-label>Name</mat-label>
      <input
        [(ngModel)]="nameVal"
        required="true"
        name="name"
        spellcheck="false"
        matInput
        placeholder="Ex. John Doe"
        class="name-form"
      />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Email</mat-label>
      <input
        required="true"
        [(ngModel)]="emailVal"
        type="email"
        matInput
        email
        name="email"
        spellcheck="false"
        placeholder="Ex. johndoe@gmail.com"
        class="email-form"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" class="example-full-width">
      <mat-label>Message</mat-label>
      <textarea
        [(ngModel)]="messageVal"
        name="message"
        class="message-form"
        matInput
      ></textarea>
    </mat-form-field>
    <div
      class="g-recaptcha"
      data-sitekey="6LegENUaAAAAACnmfu0jgW2mYDPycsgw8Q5nGqJY"
    ></div>
  </form>
</div>
<div mat-dialog-actions>
  <button
    value="Send"
    color="accent"
    mat-flat-button
    mat-dialog-close
    (click)="sendMessage()"
  >
    Send
  </button>
</div>
