export const environment = {
  firebase: {
    projectId: 'herkitchenseattle',
    appId: '1:532110473531:web:b6cdda638b727921d49f1e',
    databaseURL: 'https://herkitchenseattle-default-rtdb.firebaseio.com',
    storageBucket: 'herkitchenseattle.appspot.com',
    locationId: 'us-west3',
    apiKey: 'AIzaSyBouOK3qYFSPtttpQTIUPR6WDy0AlNvbNY',
    authDomain: 'herkitchenseattle.firebaseapp.com',
    messagingSenderId: '532110473531',
    measurementId: 'G-1W9PF6P5JD',
  },
  production: true
};
