import { Location } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, NgModule } from '@angular/core';
import {
  getAnalytics,
  provideAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material-experimental/mdc-dialog';
import { MatMenuModule } from '@angular/material-experimental/mdc-menu';
import { MatSnackBarModule } from '@angular/material-experimental/mdc-snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonDialogComponent } from './contact-button/button-dialog/button-dialog.component';
import { ErrorDialogComponent } from './contact-button/button-dialog/error-dialog/error-dialog.component';
import { SecondDialogComponent } from './contact-button/button-dialog/second-dialog/second-dialog.component';
import { ContactButtonComponent } from './contact-button/contact-button.component';
import { DirectivesModule } from './directives/directives.module';
import { CookieComponent } from './easter-eggs/cookie/cookie.component';
import { FooterComponent } from './footer/footer.component';
import { DialogComponent } from './navbar/dialog/dialog.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AboutSiteComponent } from './pages/about-site/about-site.component';
import { AboutComponent } from './pages/about/about.component';
import { BrandingComponent } from './pages/branding/branding.component';
import { ChangelogComponent } from './pages/changelog/changelog.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FacebookComponent } from './pages/facebook/facebook.component';
import { HomeComponent } from './pages/home/home.component';
import { InstagramComponent } from './pages/instagram/instagram.component';
import { TwitterComponent } from './pages/twitter/twitter.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ContactComponent,
    ContactButtonComponent,
    FacebookComponent,
    FooterComponent,
    HomeComponent,
    InstagramComponent,
    NavbarComponent,
    PageNotFoundComponent,
    DialogComponent,
    ButtonDialogComponent,
    ErrorDialogComponent,
    SecondDialogComponent,
    BrandingComponent,
    CookieComponent,
    AboutSiteComponent,
    TwitterComponent,
    ChangelogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonModule,
    MatMenuModule,
    MatDividerModule,

    DirectivesModule,

    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatListModule,

    HttpClientModule,
    RouterModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
  ],
  providers: [
    Location,
    Title,
    { provide: new InjectionToken<Window>('Window'), useFactory: () => window },
    ScreenTrackingService,
    UserTrackingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
